import GreetingSection from "../component/layout/header/GreetingSection";

function About() {
    return (
        <div>
            <GreetingSection />
        </div>
    );
}

export default About;